<template>
  <div class="section--maps">
    <div class="mt-2">
      <div class="subtitle--text">{{ $t('general.amenity') }}</div>
      <hr />
      <div class="row">
        <div class="col-12 col-md-6" v-for="(item, k) in amenities" :key="k">
          <ul class="amenity--item ma-0">
            <li>{{ item }}</li>
          </ul>
        </div>
      </div>
      <div class="subtitle--text mt-5">{{ $t('general.mapLoc') }}</div>
      <hr />
    </div>
    <!-- <div class="map-container d-flex justify-center align-center" id="map" style="height:450px;">
            <h4>Maps</h4>
        </div> -->
    <div class="map--wrapper">
      <iframe
        :src="google_map_url"
        width="100%"
        height="450"
        frameborder="0"
        style="border: 0"
        allowfullscreen
        loading="lazy"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    amenities: {
      type: Array,
    },
    google_map_url: {
      type: String,
    },
  },
  data: () => ({
    map: '',
  }),
  mounted() {
    this.$nextTick(() => {
      // this.initMap();
    });
  },
  methods: {
    initMap() {
      let self = this;
      let icon = {
        url: '/img/map_pin.png', // url
        // eslint-disable-next-line no-undef
        scaledSize: new google.maps.Size(50, 50), // scaled size
        // eslint-disable-next-line no-undef
        origin: new google.maps.Point(0, 0), // origin
        // eslint-disable-next-line no-undef
        anchor: new google.maps.Point(25, 50), // anchor
      };
      const element = document.getElementById('map');
      const options = {
        center: {
          lat: -6.191629,
          lng: 106.671293,
        },
        mapType: 'hybrid',
        zoom: 16,
        maxZoom: 18,
        zoomControl: true,
        zoomControlOptions: {
          // eslint-disable-next-line no-undef
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        streetViewControl: true,
      };
      // eslint-disable-next-line no-undef
      self.map = new google.maps.Map(element, options);

      // eslint-disable-next-line
      let marker = new google.maps.Marker({
        // eslint-disable-next-line no-undef
        position: new google.maps.LatLng(s - 6.191838, 106.671251),
        icon: icon,
        map: self.map,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.map-container {
  background-color: #ececec;
}
</style>
